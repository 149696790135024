.wrapper {
    margin-top: 20vh;
    display: flex;
    width: 100%;
    align-items: center;

    .container {
        // display: flex;
        width: 100%;
        // top: 100%;
        // flex-direction: column;
        // align-items: center;
        // // justify-content: center;

        .content-login {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 450px;
            // align-items: center;

            .content-right {
                height: auto;
                width: 616px;
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                .title {
                    display: block;
                    font-size: 4rem;
                    line-height: 2.8rem;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                }

                .login-form {
                    display: flex;
                    flex-direction: column;
                    row-gap: 24px;

                    .form-item-input {
                        width: 100%;
                        height: 80px;
                        border: 2px solid #ffffff;
                        border-radius: 89px;
                        padding-left: 38px;
                        display: flex;
                        align-items: center;
                        // flex-direction: column;
                        column-gap: 8px;
                        // gap: 10px;

                        input {
                            height: 100%;
                            width: 100%;
                            font-size: 3rem;
                            line-height: 3rem;
                            outline: none;
                            border: 1px solid transparent;
                            background-color: transparent;
                            color: #ffffff;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        input:-webkit-autofill:active {
                            transition: background-color 5000s ease-in-out 0s;
                            -webkit-text-fill-color: #ffffff !important;
                        }
                    }

                    p {
                        color: #ffff;
                        padding-left: 40px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                    }

                    .password-action {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 42px;

                        .remember-password {
                            display: flex;
                            align-items: center;
                            color: #ffffff;
                            column-gap: 6px;

                            input[type='checkbox'] {
                                width: 25px;
                                height: 25px;
                                border: 2px solid #ffffff;
                                border-radius: 12px;
                                outline: none;
                            }

                            span {
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }

                        .forgot-password {
                            display: flex;
                            align-items: center;
                            font-size: 1.4rem;
                            line-height: 1.7rem;
                            color: #ffffff;
                            font-style: italic;
                        }
                    }

                    .password-or {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 42px;
                        color: #ffffff;
                    }

                    .btn-login {
                        width: 100%;
                        height: 55px;
                        background: #25aae1;
                        border-radius: 50px;
                        outline: none;
                        border-color: transparent;
                        color: #ffffff;
                        font-size: 2rem;
                        line-height: 2.7rem;

                        &:hover {
                            cursor: pointer;
                            background-color: #158ec1;
                        }
                    }
                }
            }
        }

        .content-login-social {
            padding-top: 15px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100px;
            gap: 15px;

            .content-login-social-detail {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                img {
                    cursor: pointer;
                }
            }

            .content-login-register {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                p {
                    color: #ffff;
                }
            }
        }
    }
}
