.card-news-item {
  width: 47%;
  display: flex;
  column-gap: 24px;
  min-height: 140px;
  justify-content: space-between;
  padding: 16px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 12px;

  .card-news-left {
    display: flex;
    flex-direction: column;

    .new-news {
      display: flex;
      flex-direction: column;
      // height: 180px;
      .new-news-content {
        display: flex;
        margin-top: 8px;
      }
    }

    img {
      height: 64px;
      width: 40%;
      border-radius: 5px;
      object-fit: contain;
    }

    .title {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      visibility: visible;
      &:hover {
        cursor: pointer;
      }
    }
    .category {
      border: 1px solid #019db4;
      border-radius: 12px;
    }

    .status-btn.in-progress {
      padding: 8px 16px;
      background: #e7fcff;
      border-radius: 12px;
      font-size: 14px;
      border: 1px solid #ddd;
    }
    .des {
      padding-right: 16px;
      flex: 4 1;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #072d4b;
      opacity: 0.6;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      visibility: visible;
    }

    .card-bottom {
      flex: 1;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: #222121;
      opacity: 0.4;
      padding-top: 8px;
    }
  }

  img {
    width: 160px;
    height: 120px;
    border-radius: 4px;
  }
}
