.ant-row .ant-form-item-row {
    display: block !important;
}

.ant-form-item-label {
    overflow: visible;
}

.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .main-content {
            width: 100%;
            height: auto;

            .form-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;

                .form-inner {
                    display: flex;
                    flex-direction: column;
                    row-gap: 50px;
                }

                .bottom-form {
                    display: flex;
                    column-gap: 20px;
                    .form-left {
                        width: 70%;

                        .form-left-group {
                            display: flex;
                            flex-direction: column;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 25px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }

                            .form-group {
                                .input-7 {
                                    width: 100%;
                                    position: relative;
                                    height: 50px;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                    position: absolute;
                                    z-index: 1;
                                }
                            }
                        }
                    }

                    .form-right {
                        margin-top: 16px;
                        flex: 1;

                        .form-right-group {
                            display: flex;
                            flex-direction: column;
                            height: 74px;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 10px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }

                            .form-group {
                                .input-3 {
                                    width: 100%;
                                    height: 50px;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }

                                .select-group > div {
                                    border-radius: 18px;
                                    height: 50px;
                                    outline: none;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;

                                    > span:nth-child(2) {
                                        font-size: 1.6rem;
                                        line-height: 2.2rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }
                }

                .action-submit {
                    width: 100%;
                    text-align: center;
                    .btn-submit {
                        width: fit-content;
                        padding: 10px 14px;
                        background: #4285f4;
                        border-radius: 10px;
                        outline: none;
                        border: none;
                        color: var(--white-color);
                        font-size: 2rem;
                        line-height: 2.4rem;
                    }
                }
            }
        }
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px !important;
}
