@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300&family=Fira+Code:wght@400;600;700&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,300&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import 'antd/dist/antd.css';
@import 'react-toastify/dist/ReactToastify.css';

:root {
    --white: #fff;
    --black: #000;
    --primary: #25aae1;
    --primary-font: 'Inter', sans-serif;
    --font-fira-code: 'Fira Code', monospace;
    --header-height: 100px;
    --font-weight-600: 600;
    --font-weight-500: 500;
    --primary-color-admin: rgba(37, 170, 225, 1);
    --white-color: #ffffff;
    --error-message-color: rgba(251, 37, 37, 1);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
}

html {
    font-size: 62.5%;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a[href] {
    text-decoration: none;
}

body {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-500);
    font-size: 16px;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

.modal-reg-store {
    width: 780px !important;
}
