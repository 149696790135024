.document {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .documents-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    height: auto;
    margin-top: 30px;
    border-radius: 50px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .btn {
      border-radius: 10px;
      margin: 10px;
      // height       : auto;
      font-size: 2rem;
      border: solid 1px #fff;

      .icon-color path {
        fill: red;
        // filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
      }
    }
  }

  .event-document-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // column-gap: 25px;
    width: 100%;

    p {
      cursor: pointer;
    }

    h2 {
      padding: 25px;
      font-size: 3rem;
    }
  }

  .icon-doc {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .icon-img {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url("../../../assets/images/doocuments/image-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .icon-color-active {
    path {
      stroke: #019db4 !important;
    }
  }
}
