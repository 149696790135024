.wrapper {
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 20px;

  button {
    cursor: pointer;
  }

  .inner {
    width: 100%;
    height: 100%;

    .header-main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .header-title {
        display: flex;
        column-gap: 8px;
        align-items: center;
        font-size: 1.4rem;
        color: var(--primary-color-admin);

        svg {
          height: 10px;
          width: 10px;
          margin-top: 2px;
        }
      }

      .header-search {
        display: flex;
        justify-content: space-between;

        .search-input {
          width: 80%;
          height: 50px;

          #search-business {
            min-width: 100%;
            height: 50px;
            border: 1.5px solid #25aae1;
            border-radius: 14px;
            outline: none;
            padding-left: 10px;
            font-size: 1.6rem;
            line-height: 2.2rem;
          }
        }

        .btn-search {
          min-width: 17%;
          height: 50px;
          background: #25aae1;
          border-radius: 14px;
          border-color: transparent;
          color: #ffffff;
          font-size: 16px;
        }
      }

      .filter {
        & > div > div {
          border-radius: 18px;
          height: 40px;
          outline: none;
          border: 1.5px solid #25aae1;
          border-radius: 14px;
          padding: 8px 16px;

          > span:nth-child(2) {
            font-size: 1.6rem;
            line-height: 2.2rem;
            display: flex;
            align-items: center;
          }
        }
      }

      .header-create-group {
        width: 100%;
        height: 60px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 14px;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.8rem;
          column-gap: 18px;

          .image {
            flex: 1.5;
            display: flex;
            justify-content: center;
          }

          .name {
            flex: 1.5;
            display: flex;
            justify-content: flex-start;
            padding-left: 14px;
          }

          .time {
            flex: 1.5;
            display: flex;
            padding-left: 14px;
            justify-content: flex-start;
          }

          .program-name {
            flex: 2;
            display: flex;
            padding-left: 14px;
            justify-content: flex-start;
          }

          .area {
            flex: 1;
            display: flex;
            justify-content: center;
            padding-right: 12px;
          }

          .status {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding-right: 42px;
          }
        }
      }
    }

    .main-content {
      margin-top: 22px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 26px;

      .event-group {
        display: flex;
        width: 100%;
        height: 134px;
        column-gap: 18px;
        background: #fafafa;

        .event-avatar {
          // flex: 1.5;
          width: max(20%, 150px);
          display: flex;
          padding-left: 18px;
          padding: 10px;

          img {
            border-radius: 4px;
            width: 150px;
          }
        }

        .event-name {
          flex: 1.5;
          font-weight: 500;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.8rem;
          padding: 14px;
          // cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .event-time {
          flex: 1.5;
          font-weight: 500;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.8rem;
          padding: 14px;
        }

        .event-title {
          flex: 2;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.8rem;
          color: #4285f4;
          padding: 14px;
        }

        .event-type {
          flex: 1.5;
          display: flex;
          justify-content: center;
          padding: 14px;
        }

        .event-status {
          flex: 1;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 100%;
          row-gap: 12px;
          padding-right: 18px;
          padding: 14px;

          .comfirmed {
            padding: 8px 12px;
            box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1),
              inset 2px 2px 4px #39b54a;
            border-radius: 10px;
            color: #ffffff;
            background: #39b54a;
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }

          .uncomfirmed {
            padding: 8px 12px;
            min-width: 100px;
            border-radius: 10px;
            background: #fb2525;
            box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1),
              inset 2px 2px 4px #f74440;
            color: #ffffff;
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }

          .edit {
            padding: 8px 12px;
            min-width: 100px;
            background: #ffffff;
            box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1),
              inset 2px 2px 4px #d9d9d9;
            border-radius: 10px;
            color: #4285f4;
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .pagination-event {
    margin-top: 36px;
    display: flex;
    justify-content: center;
  }
}
