.wrapper {
  padding: 10px 36px 36px 36px;
  display: flex;
  flex-direction: column;

  .inner {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .title {
      display: flex;
      justify-content: center;
    }

    input {
      width: 280px;
      border: rgb(212, 187, 187) solid 1px;
      height: 40px;
      font-size: 16px;
      border-radius: 10px;
      padding: 10px;
    }

    .container {
      display: flex;
      flex-direction: row;

      .content-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 60px;

        .content-email {
          display: flex;
          flex-direction: column;
        }
      }

      .content-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding-left: 30px;

        .phone {
          margin-top: -11px;
        }

        .pick-area {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 10px;
          margin: 0px;

          .male {
            display: flex;
            margin-top: 10px;
            gap: 10px;
            flex-direction: row;
          }

          .female {
            display: flex;
            margin-top: 10px;
            gap: 10px;
          }

          input {
            height: 20px;
            width: 20px;
          }
        }
      }
    }

    .uploadimage {
      margin-top: 40px;
      display: flex;
      align-items: center;

      // flex-direction: column;
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 15px;
        border-radius: 5px;
        column-gap: 8px;
        cursor: pointer;
        background-color: #eee;

        &:hover {
          background-color: #dcdcdc;
        }
      }

      .uploadimage {
        border: none;
        height: 50px;
        width: 240px;
        cursor: pointer;
      }

      input {
        width: 622px;
      }
    }

    .preview {
      display: flex;
      justify-content: center;
      height: 150px;
      border: 1px dashed #ccc;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      color: #ccc;

      img {
        border: none;
        outline: none;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .btn-submit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }
  }
}
