.company-project-item {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);

  .image-group {
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      object-fit: cover;
    }
  }

  .project-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 16px;
    justify-content: space-evenly;

    .top-detail {
      .title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        visibility: visible;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      a {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2rem;
        color: unset;
      }
    }

    .description {
      font-size: 1.6rem;
      margin-top: 8px;
      height: auto;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      visibility: visible;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #838383;
    }

    .bottom-detail {
      .capital {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 24px;
        color: #39b54a;
        padding-top: 16px;
      }

      .location {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 8px;
        color: #838383;
      }
    }
  }
}