.project-detail-container-inner {
  width: 100%;

  .project-tab {
    width: 100%;

    ul {
      display: flex;
      column-gap: 24px;
      margin-bottom: 18px;

      li {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.2rem;

        &:hover {
          cursor: pointer;
        }
      }

      li.active {
        font-size: 20px;
        font-weight: 600;
        color: #25aae1;
        padding-bottom: 5px;
        border-bottom: 2px solid #25aae1;
      }
    }
  }
}
