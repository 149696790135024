.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .main-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                label {
                    font-size: 20px;
                }

                .content-detail-1 {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 33%;
                    border-radius: 10px;

                    // input {
                    //     border: 1px solid #25aae1;
                    //     width: 500px;
                    //     height: 50px;
                    //     font-size: 18px;
                    //     border-radius: 12px;
                    //     padding: 10px;
                    // }
                }

                .content-detail-2 {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                    margin-top: 12px;

                    label {
                        font-size: 15px;
                    }

                    .file-upload {
                        display: flex;
                        width: 100%;

                        .image-container {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .btn-add {
            width: fit-content;
            padding: 8px 16px;
            background: #25aae1;
            color: #ffffff;
            border-radius: 5px;
            transition: 0.3s;
            margin-top: 24px;

            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }
}
