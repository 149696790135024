.wrapper {
  display: flex;
  flex-direction: row;

  .inner {
    display: flex;
    flex-direction: column;
    padding: 30px 100px 100px 100px;
    width: 100%;
    gap: 80px;
    .card-top-project {
      width: 100%;
      //   height: 510px;
      border-bottom: 1px solid #ddd;
      padding: 40px 140px;

      .card-top-left-wrap {
        width: 100%;

        .image-group {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          row-gap: 16px;
          width: 100%;

          .big-image {
            width: 100%;
            // height: 400px;
            display: flex;
            justify-content: center;

            img {
              min-height: 310px;
              max-height: 500px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .small-image {
            display: flex;
            column-gap: 8px;

            img {
              width: 60px;
              height: 39px;
              object-fit: contain;

              &:hover {
                cursor: pointer;
              }
            }

            img.small-active {
              border: 1px solid #ff0000;
            }
          }
        }
      }

      .card-top-right-wrap {
        width: 100%;

        .content2 {
          display: flex;

          flex-direction: column;
          row-gap: 8px;
          padding: 20px 0 0 20px;

          .title {
            font-weight: 600;
            font-size: 2.4rem;
            // line-height: 3.8rem;
          }

          .title-store {
            font-weight: 400;
            font-size: 2rem;
            padding-top: 16px;
            // line-height: 3.4rem;
          }

          .title-name {
            padding-top: 16px;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 3.4rem;
            color: #838383;
          }

          .title-price {
            font-weight: 400;
            font-size: 20px;
            padding-top: 16px;
            // line-height: 3.4rem;
          }

          .title-share {
            padding-top: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 34px;
            // padding-bottom: 8px;
          }

          .btn-group {
            margin-top: 16px;
            display: flex;
            column-gap: 25px;

            .love-btn {
              border: 1px solid #019db4;
              border-radius: 14px;
              padding: 8px 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 12px;
              transition: 0.3s;
              color: #019db4;

              svg {
                font-size: 2rem;
                color: #019db4;
              }

              &:hover {
                cursor: pointer;
                background-color: #019db4;
                color: #ffffff;
                border: 1px solid #019db4;
                svg {
                  font-size: 2rem;
                  color: #ffffff;
                }
              }
            }

            .unfavorite-btn {
              background-color: #019db4;
              color: #ffffff;
              border: 1px solid #019db4;
              border-radius: 14px;
              padding: 8px 24px;
              transition: 0.3s;
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 12px;

              svg {
                font-size: 2rem;
              }

              &:hover {
                cursor: pointer;
                background-color: #ffffff;
                color: #000000;
                border: 1px solid #000000;
              }
            }

            .addToCart-btn {
              border: 1px solid #000000;
              border-radius: 14px;
              padding: 8px 24px;
              border: 1px solid #fb2525;
              display: flex;
              align-items: center;
              column-gap: 12px;
              justify-content: center;
              color: #fb2525;
              transition: 0.3s;

              svg {
                font-size: 2rem;
              }

              &:hover {
                background-color: #fb2525;
                color: #ffffff;
                border: 1px solid #fb2525;
              }
            }
          }

          .social {
            padding-top: 16px;
            display: flex;
            gap: 10px;

            img {
              width: 32px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .container1 {
      display: flex;
      width: 100%;
      padding-left: 50px;
      //   border-left: 4px solid #39b54a;

      .content1 {
        display: flex;
        flex-direction: column-reverse;
        width: 50%;
        gap: 30px;

        .content1-detail1 {
          display: flex;
          gap: 40px;
          padding-left: 60px;

          .img-small {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            cursor: pointer;
          }

          .active {
            width: 100px;
            height: 100px;
            cursor: pointer;
            border: 2px solid red;
          }
        }

        .content1-detail2 {
          .img-large {
            width: 90%;
            object-fit: contain;
          }
        }
      }
    }

    .container3 {
      display: flex;
      width: 100%;
      //   border-left: 4px solid #d5d916;

      .product {
        padding-left: 40px;

        p {
          font-weight: 600;
          font-size: 24px;
          line-height: 18px;
        }

        .list-product {
          display: flex;
          margin-top: 24px;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 16px;
        }
      }
    }

    .container2 {
      display: flex;
      width: 100%;
      //   border-left: 4px solid #25aae1;
    }
  }
}
