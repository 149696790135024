.card-product-wrap {
  width: 100%;
  height: 394px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: ease 0.4s;

  &:hover {
    transform: translateY(-10px);
  }

  .image-group {
    width: 100%;
    height: 190px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .product-detail {
    padding: 16px;
    display: flex;
    flex-direction: column;

    .prod-category {
      background: #e7fcff;
      border-radius: 4px;
      width: fit-content;
      padding: 4px 8px;
      span {
        color: black;
        font-style: medium;
      }
    }

    .prod-name {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      visibility: visible;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }
    }

    .prod-price {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;

      .original {
        text-decoration: line-through;
        margin-right: 8px;
        color: #828282;
      }
    }

    .rate-group {
      img {
        margin-right: 4px;
      }
    }

    .prod-store-group {
      color: #828282;
      display: flex;
      gap: 8px;
      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        visibility: visible;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
