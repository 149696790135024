.create-product-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            height: auto;
            display: flex;
            column-gap: 2%;
            margin-top: 12px;

            .content-left {
                width: 70%;
                height: auto;
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                .general-info {
                    width: 100%;
                    min-height: 580px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .general-info-title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .form-group {
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        label {
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            font-weight: 700;
                            color: #006889;
                        }

                        input {
                            height: 44px;
                            min-height: 44px;
                        }

                        .form-editor {
                            height: 446px;
                            min-height: 446px;
                        }
                    }

                    .form-group.quote {
                        margin-top: 10px;
                    }

                    .supplier-type-group {
                        display: flex;
                        justify-content: space-between;

                        .form-group {
                            width: 48%;
                        }

                        .form-group > div > div > div > div > div > div > div {
                            height: 44px;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .products-image {
                    width: 100%;
                    min-height: 238px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .product-image__title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .is-empty {
                        width: 170px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        h3 {
                            font-weight: 400;
                            color: #767676;
                            font-size: 14px;
                        }
                    }

                    .image-container {
                        display: flex;

                        .big-image {
                            height: 250px;
                            padding: 5px;
                            position: relative;

                            img {
                                height: 100%;
                            }
                        }

                        .small-image-container {
                            display: flex;
                            width: auto;
                            height: 250px;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            flex-direction: column;
                            .small-image {
                                height: 125px;
                                width: 125px;
                                padding: 5px;
                                margin: 0 auto;
                                position: relative;
                                // overflow: hidden;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                border: 5px solid white;

                                img {
                                    // height: min(1125px, 100%);
                                    width: 100%;
                                }

                                input[type='file'] {
                                    display: none;
                                }

                                &.upload {
                                    border: 1px dashed #c4c4c4;
                                    border-radius: 6px;

                                    label {
                                        width: 100%;
                                        height: 100%;
                                        padding: 20px 19px 16px;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: space-between;
                                        flex-direction: column;
                                        text-align: center;
                                    }
                                    h4 {
                                        color: #ababab;
                                    }

                                    img {
                                        width: 48px;
                                        height: 48px;
                                        margin: 0 auto;
                                    }
                                }
                            }
                        }

                        .delete-image {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border: 3px solid var(--hover-2);
                            z-index: 9;
                            right: -5px;
                            top: -5px;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 15px;
                            color: var(--hover-2);
                            font-weight: 600;
                            cursor: pointer;
                            transition: 0.3s ease-in-out;
                            background-color: white;
                        }
                    }
                }

                .products-price {
                    width: 100%;
                    height: 158px;
                    min-height: 158px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .products-price-title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .price-group {
                        display: flex;
                        justify-content: space-between;

                        .form-group {
                            width: 48%;
                        }

                        label {
                            font-size: 1.2rem;
                            font-weight: 700;
                            line-height: 1.6rem;
                            color: #006889;
                        }

                        input {
                            margin-top: 12px;
                        }
                    }
                }

                .transport {
                    width: 100%;
                    height: 170px;
                    min-height: 170px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .transport-title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .transport-content {
                        .transport-checkbox {
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 12px;

                            input {
                                max-width: 16px;
                            }

                            span {
                                font-size: 1.2rem;
                                font-weight: 400;
                                line-height: 1.6rem;
                                margin-left: 8px;
                            }
                        }

                        .form-group {
                            display: flex;
                            flex-direction: column;
                            row-gap: 12px;

                            label {
                                font-size: 1.2rem;
                                font-weight: 700;
                                line-height: 1.6rem;
                                color: #006889;
                            }
                        }
                    }
                }
            }

            .content-right {
                width: 28%;
                height: 826px;
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                .display {
                    width: 100%;
                    height: 148px;
                    min-height: 148px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .display-title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .group-check-display {
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        .checkbox-group {
                            display: flex;

                            input {
                                max-width: 16px;
                            }

                            label {
                                font-weight: 400;
                                font-size: 1.2rem;
                                line-height: 1.8rem;
                                margin-left: 8px;
                            }
                        }
                    }
                }

                .products-group {
                    width: 100%;
                    height: 136px;
                    min-height: 136px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;

                    .products-title {
                        padding-bottom: 12px;
                        border-bottom: 0.5px solid #dcdcdc;
                        margin-bottom: 18px;

                        h3 {
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            font-weight: 700;
                        }
                    }

                    .form-group {
                        display: flex;
                        flex-direction: column;
                        row-gap: 12px;

                        label {
                            font-size: 1.2rem;
                            font-weight: 700;
                            line-height: 1.6rem;
                            color: #006889;
                        }
                    }
                }

                .brand {
                    width: 100%;
                    height: 112px;
                    min-height: 112px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    padding: 16px;
                }

                .promotion {
                    width: 100%;
                    height: 100px;
                    min-height: 100px;
                    background: #ffffff;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    padding: 16px;
                    border-radius: 8px;
                }
            }
        }

        .submit-action {
            width: 100%;
            margin-top: 22px;
            display: flex;
            justify-content: center;
            color: #ffffff;

            .btn-submit {
                padding: 12px 20px;
                background: #25aae1;
                border-radius: 4px;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
        }
    }
}
