.course-register {
  width: 100%;
  padding-bottom: 32px;

  &__header {
    min-height: 100px;
    background-image: url("../../../../../assets/images/bg.png");
    padding: 8px 100px;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    display: flex;
    flex-direction: column;

    .course-register__name {
      font-size: 2.8rem;
      font-weight: 700;
      padding-bottom: 16px;
    }

    .course-register__location {
      font-size: 2rem;
      font-weight: 600;
      color: aliceblue;
    }
    .course-register__time {
      font-size: 2rem;
      padding-top: 8px;
      font-weight: 600;
      color: aliceblue;
    }
  }

  .course-register__content {
    display: flex;
    padding: 0 100px;
    column-gap: 3%;
    margin-top: 24px;

    .content-left {
      flex: 7;
    }

    .content-right {
      flex: 3;
    }
  }
}
