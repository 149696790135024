.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }

            .header-search {
                display: flex;
                justify-content: space-between;

                .search-input {
                    width: 80%;
                    height: 50px;

                    #search-business {
                        min-width: 100%;
                        height: 50px;
                        border: 1.5px solid #25aae1;
                        border-radius: 14px;
                        outline: none;
                        padding-left: 10px;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                    }
                }

                .btn-search {
                    min-width: 17%;
                    height: 50px;
                    background: #25aae1;
                    border-radius: 14px;
                    border-color: transparent;
                    color: #ffffff;
                    font-size: 16px;
                }
            }

            .list-btn {
                display: flex;
                column-gap: 24px;

                .comfirmed-btn {
                    background: rgba(174, 174, 192, 0.3);
                    box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #f0f0f3;
                    border-radius: 6px;
                    padding: 12px 24px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .uncomfirmed-btn {
                    background: rgba(174, 174, 192, 0.3);
                    box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #f0f0f3;
                    border-radius: 6px;
                    padding: 12px 24px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .active.comfirmed-btn {
                    border: 1px solid rgba(27, 36, 133, 0.1);
                    box-shadow: 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
                        inset 2px 2px 4px #f0f0f3;
                    border-radius: 6px;
                    background: #fafbfc;
                }

                .active.uncomfirmed-btn {
                    border: 1px solid rgba(27, 36, 133, 0.1);
                    box-shadow: 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
                        inset 2px 2px 4px #f0f0f3;
                    border-radius: 6px;
                    background: #fafbfc;
                }
            }

            .header-create-group {
                width: 100%;
                height: 60px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                border-radius: 14px;

                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.8rem;

                    .image {
                        flex: 1.5;
                        display: flex;
                        justify-content: flex-start;
                        padding-left: 32px;
                    }

                    .time {
                        flex: 2;
                        display: flex;
                        justify-content: flex-start;
                        padding-left: 7%;
                    }

                    .title {
                        flex: 2;
                        display: flex;
                        justify-content: flex-start;
                        padding-left: 48px;
                    }

                    .type {
                        flex: 1.5;
                        display: flex;
                        justify-content: center;
                        padding-right: 48px;
                    }

                    .status {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        padding-right: 18px;
                    }
                }
            }
        }

        .main-content {
            margin-top: 22px;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 4px;

            .post-group {
                display: flex;
                align-items: center;
                min-width: 100%;
                // height: 134px;
                column-gap: 18px;
                background: #fafafa;

                .post-avatar {
                    flex: 1.5;
                    display: flex;
                    padding-left: 18px;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }

                .post-time {
                    flex: 2;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                    padding: 14px;
                }

                .post-title {
                    flex: 2;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                    color: #4285f4;
                    padding: 14px;
                }

                .post-type {
                    flex: 1.5;
                    display: flex;
                    justify-content: center;
                    padding: 14px;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }

                .post-status {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    row-gap: 12px;
                    padding-right: 18px;
                    padding: 14px;
                    font-size: 2rem;
                    column-gap: 12px;

                    .comfirm {
                        color: #369313;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .uncomfirm {
                        color: #fb2525;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}