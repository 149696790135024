.investment-solutions-wrapper {
  width: 100%;
  padding: 48px 0;

  .list-investment {
    display: flex;
    flex-direction: column;
    padding: 0 140px;
  }

  .member-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 100px 0 100px;

    h3 {
      font-size: 2.8rem;
      font-weight: 700;
      
    }

    .list-member {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // gap: 4%;
      flex-wrap: wrap;
    }
  }
}