.change-password {
    width: 100%;

    .inner {
        padding: 20px;
        .title {
            border-bottom: 1px solid #000000;
            padding-bottom: 10px;
            color: rgba(37, 170, 225, 1);
            span {
                font-weight: 700;
                font-size: 2.4rem;
                line-height: 2.8rem;
            }
        }

        .content-main {
            width: 100%;
            margin-top: 10px;
            form {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .form-group {
                    display: flex;
                    align-items: center;

                    label {
                        min-width: 200px;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 2.8rem;
                    }

                    .input-group {
                        display: flex;
                        border: 1px solid #25aae1;
                        border-radius: 10px;
                        align-items: center;
                        min-width: 50%;
                        padding: 0 16px;

                        input {
                            background: transparent;
                            flex: 1 1;
                            display: flex;
                            align-items: center;
                            height: 32px;
                        }
                    }

                    .error-message {
                        margin-left: 10px;
                        color: var(--error-message-color);
                    }
                }

                .submit-action {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: center;

                    .btn-submit {
                        width: fit-content;
                        padding: 6px 12px;
                        background: #4285f4;
                        border-radius: 10px;
                        color: var(--white-color);
                        transition: 0.3s;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}
