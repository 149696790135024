.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .top-content {
            width: 100%;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 2rem;
                font-weight: 400;
                line-height: 2.4rem;

                span {
                    font-size: 2rem;
                    font-weight: 600;
                    line-height: 2.4rem;
                }
            }

            .notify {
                display: flex;
                flex-direction: column;
                margin-top: 12px;
                row-gap: 10px;

                h2 {
                    margin: 0;
                }

                .notify-content {
                    border: 1px solid #25aae1;
                    border-radius: 10px;
                    padding: 0 20px;
                    min-height: 50px;
                    display: flex;
                    align-items: center;

                    .notify-time {
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 1.9rem;
                        margin-right: 40px;
                    }

                    .notify-text {
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 1.9rem;
                        margin-right: 40px;
                    }
                }
            }

            .order-details-group {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                column-gap: 22px;
                margin-top: 16px;

                .parent-group {
                    width: 100%;
                    height: auto;

                    .details-group {
                        background: #ffffff;
                        border-radius: 10px;
                        min-height: 150px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                        .name {
                            font-size: 2rem;
                            line-height: 2.4rem;
                            font-weight: 600;
                        }

                        .label {
                            font-size: 1.6rem;
                            line-height: 1.9rem;
                            font-weight: 600;
                        }

                        .content {
                            font-size: 1.6rem;
                            line-height: 1.9rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
