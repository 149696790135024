.wrapper {
    border-radius: 4px;
    background: var(--white-color);
    box-shadow: 0 -4px 32px rgb(0 0 0 / 20%);
    color: #1d2129;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    transition: all 0.3s ease-in-out;
    will-change: opacity, transform;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-8px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
