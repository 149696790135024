.my-cart-wrapper {
    width: 100%;
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 24px;

    .my-cart__header {
        border-bottom: 3px solid #ccc;
        padding-bottom: 8px;
        margin-bottom: 18px;
    }

    .ant-tree-indent {
        display: none;
    }

    .ant-tree-switcher.ant-tree-switcher-noop {
        display: none;
    }

    .ant-tree-switcher.ant-tree-switcher_open {
        display: none;
    }

    .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last {
        width: 100%;

        .ant-tree-treenode-switcher-close {
            width: 100%;
        }

        .ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
            width: 100%;
        }

        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
            width: 100%;
        }

        .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close {
            width: 100%;
        }
    }

    .ant-tree-treenode.ant-tree-treenode-switcher-open {
        width: 100%;
    }

    .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
        width: 100%;
    }

    .ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last {
        width: 100%;
    }

    .ant-tree-treenode.ant-tree-treenode-switcher-close {
        width: 100%;
    }

    .ant-tree.ant-tree-treenode {
        align-items: center !important;
    }

    .cart-details__header {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 12px;
        padding-bottom: 26px;

        input {
            width: 20px;
            height: 20px;
        }

        span {
            color: #344a9f;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
        }

        svg {
            font-size: 1.4rem;
            color: rgb(0, 128, 0);
        }
    }

    .my-cart-title-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: -4px;

        h2 {
            color: #344a9f;
        }

        button {
            background: #4285f4;
            border-radius: 5px;
            padding: 8px 24px;
            color: #ffffff;
            transition: 0.3s;

            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

    h2 {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 2.8rem;
    }

    .commodity-details {
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        column-gap: 16px;

        input {
            width: 20px;
            height: 20px;
        }

        img {
            width: 130px;
            // max-height: 95px;
        }

        .commodity-right__group {
            width: 83%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .info-commodity {
            height: 90px;
            flex: 1;
            max-width: 50%;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 6px 0;
            flex: 1 1;

            .commodity-name {
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 2.2rem;
            }

            .color-size-group {
                display: flex;
                column-gap: 24px;

                .color {
                    label {
                        color: #9b9b9b;
                    }
                }

                .size {
                    label {
                        color: #9b9b9b;
                    }
                }
            }
        }

        .commodity-price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            min-width: 120px;
            width: 100px;
            height: 90px;

            .current-price {
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.2rem;
            }

            .old-price {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.2rem;
                text-decoration-line: line-through;
                color: #9b9b9b;
            }
        }

        .commodity-quantity {
            height: 90px;
            min-width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 16px;

            button {
                display: flex;
                align-items: center;
                padding: 2px 4px;
            }

            // button:disabled {
            //     color: #9b9b9b;
            // }

            input {
                text-align: center;
            }

            .commodity-quantity__group {
                padding: 4px 4px;
                border: 1px solid #dbdbdb;
                border-radius: 3px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .minus {
                    padding: 4px;
                    border-right: 1px solid #dbdbdb;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .plus {
                    padding: 4px;
                    border-left: 1px solid #dbdbdb;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .delete-commodity-btn {
                font-size: 2rem;
                color: rgba(251, 37, 37, 1);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .select-all {
        width: 100%;
        border: 1px solid #4285f4;
        border-radius: 3px;
        padding: 12px 24px;

        span {
            color: #25aae1;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }

    .my-cart__left-group {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .my-cart__left {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            flex: 1 1;
            margin-right: 60px;

            .cart-details {
                width: 100%;
                padding: 12px;
                border: 1px solid #4285f4;
                border-radius: 3px;

                .cart-details__header {
                    height: 50px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 12px;

                    input {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        color: #344a9f;
                        font-weight: 500;
                        font-size: 1.8rem;
                        line-height: 2.2rem;
                    }

                    svg {
                        font-size: 1.4rem;
                        color: rgb(0, 128, 0);
                    }
                }

                .cart-details__main {
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;

                    .commodity-details {
                        min-height: 100px;
                        display: flex;
                        align-items: center;
                        column-gap: 16px;

                        input {
                            width: 20px;
                            height: 20px;
                        }

                        img {
                            width: 130px;
                            // max-height: 95px;
                        }

                        .commodity-right__group {
                            width: 83%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .info-commodity {
                            height: 90px;
                            flex: 1;
                            max-width: 50%;
                            width: 500px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 6px 0;
                            flex: 1 1;

                            .commodity-name {
                                font-weight: 600;
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                            }

                            .color-size-group {
                                display: flex;
                                column-gap: 24px;

                                .color {
                                    label {
                                        color: #9b9b9b;
                                    }
                                }

                                .size {
                                    label {
                                        color: #9b9b9b;
                                    }
                                }
                            }
                        }

                        .commodity-price {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            min-width: 120px;
                            width: 100px;
                            height: 90px;

                            .current-price {
                                font-weight: 500;
                                font-size: 1.8rem;
                                line-height: 2.2rem;
                            }

                            .old-price {
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.2rem;
                                text-decoration-line: line-through;
                                color: #9b9b9b;
                            }
                        }

                        .commodity-quantity {
                            height: 90px;
                            min-width: 100px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding-bottom: 16px;

                            button {
                                display: flex;
                                align-items: center;
                                padding: 2px 4px;
                            }

                            // button:disabled {
                            //     color: #9b9b9b;
                            // }

                            input {
                                text-align: center;
                            }

                            .commodity-quantity__group {
                                padding: 4px 4px;
                                border: 1px solid #dbdbdb;
                                border-radius: 3px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .minus {
                                    padding: 4px;
                                    border-right: 1px solid #dbdbdb;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }

                                .plus {
                                    padding: 4px;
                                    border-left: 1px solid #dbdbdb;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }

                            .delete-commodity-btn {
                                font-size: 2rem;
                                color: rgba(251, 37, 37, 1);

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .my-cart__right-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        .my-cart__right {
            width: 100%;
            margin-top: 40px;
            border: 1px solid #4285f4;
            border-radius: 3px;
            padding: 16px;

            .order-summary {
                display: flex;
                flex-direction: column;
                row-gap: 12px;

                .order-summary__title {
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                    color: #9b9b9b;
                }

                .order-summary__group {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    label {
                        font-weight: 400;
                        font-size: 1.4rem;
                        line-height: 2.8rem;
                        color: #141417;
                    }
                }

                .total-money {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    border-top: 1px solid #edeef0;
                    padding-top: 20px;

                    .total-money__number {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        .total-money__number-price {
                            font-weight: 500;
                            font-size: 2.4rem;
                            line-height: 3rem;
                            color: #4285f4;
                        }

                        .total-money__vat {
                            font-weight: 400;
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                            color: #9b9b9b;
                        }
                    }
                }

                .voucher-code {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 16px;
                    input {
                        width: 100%;
                        background: #e6edf5;
                        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.05);
                        border-radius: 35px;
                        padding: 4px 16px;
                    }
                }
            }
        }

        .order-action {
            margin: 12px 0 24px 0;
            text-align: right;

            button {
                color: #ffffff;
                background: #4285f4;
                box-shadow: 0px 4px 8px rgba(211, 38, 38, 0.25);
                border-radius: 25px;
                padding: 14px 50px;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .payment-method {
            .payment-method__group {
                margin-top: 8px;
                display: flex;
                column-gap: 24px;
            }
        }
    }
}
