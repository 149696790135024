.all-order-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        // .content-main {
        //     width: 100%;

        //     .content-top {
        //         width: 100%;
        //         display: flex;
        //         flex-direction: column;

        //         .filter-search-group {
        //             width: 100%;
        //             height: 76px;
        //             min-height: 76px;
        //             display: flex;
        //             padding: 16px;
        //             justify-content: space-between;

        //             .filter {
        //                 width: 15%;
        //                 max-width: 15%;
        //                 height: 44px;
        //                 border: 1px solid #ababab;
        //                 border-radius: 4px;
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: space-between;
        //                 padding: 16px;

        //                 span {
        //                     font-size: 1.2rem;
        //                     line-height: 1.6rem;
        //                     font-weight: 400;
        //                 }
        //             }

        //             .search {
        //                 width: 83%;
        //                 max-width: 83%;
        //                 height: 44px;
        //                 border: 1px solid #ababab;
        //                 border-radius: 4px;
        //                 display: flex;
        //                 justify-content: space-between;
        //                 align-items: center;
        //                 padding: 8px 16px;

        //                 input {
        //                     width: 97%;
        //                     font-size: 1.2rem;
        //                     line-height: 2rem;
        //                 }
        //             }
        //         }
        //     }

        //     .content-bottom {
        //         width: 100%;
        //         padding: 16px;
        //         display: flex;
        //         flex-direction: column;
        //         row-gap: 36px;

        //         .group-orders {
        //             display: flex;
        //             flex-direction: column;
        //             border: 1px solid #25aae1;
        //             border-radius: 10px;
        //             padding: 16px;

        //             .title {
        //                 font-size: 1.6rem;
        //                 font-weight: 600;
        //                 line-height: 1.9rem;
        //                 color: rgba(37, 170, 225, 1);
        //                 margin-bottom: 8px;

        //                 svg {
        //                     margin-right: 12px;
        //                 }
        //             }

        //             .group-order-details {
        //                 width: 100%;
        //                 height: auto;
        //                 display: flex;
        //                 flex-direction: column;
        //                 row-gap: 10px;

        //                 .order-details {
        //                     width: 100%;
        //                     height: auto;
        //                     display: flex;
        //                     border-bottom: 1px solid #828282;
        //                     padding-bottom: 10px;

        //                     .order-image {
        //                         width: 70px;
        //                         height: 70px;
        //                         background-color: #ccc;
        //                         border-radius: 10px;
        //                         overflow: hidden;
        //                         margin-right: 12px;

        //                         img {
        //                             width: 100%;
        //                             height: 100%;
        //                         }
        //                     }

        //                     .right-group {
        //                         width: 100%;
        //                         display: flex;
        //                         justify-content: space-between;

        //                         .details-lefts {
        //                             display: flex;
        //                             flex-direction: column;
        //                             row-gap: 6px;

        //                             .order-name {
        //                                 font-size: 1.6rem;
        //                                 font-weight: 600;
        //                                 line-height: 1.9rem;
        //                             }

        //                             .normal-name {
        //                                 font-weight: 400;
        //                                 font-size: 1.4rem;
        //                                 line-height: 1.6rem;
        //                             }
        //                         }

        //                         .order-price {
        //                             display: flex;
        //                             flex-direction: column;
        //                             justify-content: flex-end;
        //                         }
        //                     }
        //                 }
        //             }

        //             .order-bottom {
        //                 display: flex;
        //                 flex-direction: column;
        //                 margin-top: 10px;
        //                 row-gap: 10px;
        //                 width: 100%;

        //                 .total-price {
        //                     display: flex;
        //                     width: 100%;
        //                     justify-content: flex-end;
        //                     color: #ababab;
        //                     font-weight: 600;
        //                     font-size: 2rem;
        //                     line-height: 2.4rem;

        //                     span {
        //                         color: rgba(37, 170, 225, 1);
        //                     }
        //                 }

        //                 .actions-btn {
        //                     display: flex;
        //                     width: 100%;
        //                     justify-content: flex-end;
        //                     column-gap: 20px;

        //                     .order-btn {
        //                         background-color: transparent;
        //                         border: 1px solid #25aae1;
        //                         border-radius: 10px;
        //                         padding: 4px 20px;
        //                         color: rgba(37, 170, 225, 1);
        //                         transition: 0.3s;

        //                         &:hover {
        //                             cursor: pointer;
        //                             background-color: rgba(37, 170, 225, 1);
        //                             color: var(--white-color);
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    .ant-tabs-nav-list {
        background-color: #edeff1;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .ant-tabs-tab {
            width: 100%;
            display: flex;
            justify-content: center;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.8rem;
        }
    }

    .order-desc-wrapper {
        display: flex;
        flex-direction: column;

        .order-detail {
            display: flex;
            column-gap: 2%;
            padding-bottom: 16px;
            border-bottom: 1px solid #ccc;

            .product-details {
                flex: 4;
                display: flex;
                flex-direction: column;
                row-gap: 12px;

                .product-item {
                    width: 100%;
                    display: flex;

                    .image-group {
                        width: 74px;
                        height: 48px;
                        margin-right: 12px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .product-item-detail {
                        width: 100%;

                        .product-name {
                            display: flex;
                            width: 100%;
                            column-gap: 18px;
                            justify-content: space-between;
                            color: #767676;

                            span {
                                font-weight: 700;
                                font-size: 1.4rem;
                                line-height: 1.6rem;
                                color: #006889;
                            }
                        }

                        .inventory-number {
                            display: flex;
                            width: 100%;
                            column-gap: 18px;
                            justify-content: space-between;

                            span {
                                color: #767676;
                            }
                        }
                    }
                }
            }

            .address-detail {
                flex: 3;
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                .address-title {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                    color: #292d32;
                }

                .customer-name {
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    color: #006889;
                }
            }

            .note-detail {
                flex: 3;
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .note-title {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                    color: #292d32;
                }
            }
        }

        .order-action {
            margin-top: 16px;
            display: flex;
            width: 100%;
            justify-content: space-between;

            .left-action {
                display: flex;
                column-gap: 24px;

                .action-btn {
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    transition: 0.3s;

                    &:hover {
                        cursor: pointer;
                        color: #006889;
                    }
                }
            }

            .right-action {
                button {
                    padding: 8px 16px;
                    background: rgba(0, 104, 137, 0.1);
                    border-radius: 4px;
                    color: #006889;
                    transition: 0.3s;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
