.voucher-wrapper {
    width: 100%;

    .voucher-inner {
        width: 100%;

        .voucher-title {
            margin-bottom: 16px;
            h2 {
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.4rem;
            }
        }

        .list-voucher {
            width: 100%;

            .ant-radio-group {
                display: flex;
                flex-direction: column;

                .ant-radio-wrapper {
                    span:nth-child(2) {
                        margin-right: 15px;
                    }

                    span:nth-child(2) {
                        width: 98%;
                    }
                }
            }

            .voucher-item {
                width: 100%;
                display: flex;
                margin-top: 24px;

                .name-voucher {
                    height: 100px;
                    color: #ffffff;
                    background-color: #00bfa5;
                    width: 100px;
                    padding: 10px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top: 1px solid var(--border-color);
                    border-bottom: 1px solid var(--border-color);
                    border-radius: 0.125rem 0 0 0.125rem;
                }

                .voucher-details {
                    margin-left: 24px;
                    display: flex;
                    width: 100%;

                    .voucher-details-left {
                        flex: 8;
                        display: flex;
                        flex-direction: column;
                    }

                    .voucher-details-right {
                        margin-left: 48px;
                        flex: 3;
                    }
                }
            }
        }

        .action-select {
            width: 100%;
            margin-top: 16px;
            text-align: right;

            button {
                color: #ffffff;
                background: #4285f4;
                border-radius: 5px;
                padding: 4px 16px;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
        }
    }
}
