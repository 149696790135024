.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    padding: 10px 8px;

    a {
        display: flex;
        align-items: center;
        height: 100%;
        width: 140px;

        img {
            margin: 10px 0 0 10px;
            width: 60%;
        }
    }

    .admin-profile {
        display: flex;
        column-gap: 20px;
        align-items: center;
        padding-right: 12px;

        .admin-name {
            font-size: 16px;
            font-weight: var(--font-weight-600);
            color: var(--primary-color-admin);
        }

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 2px solid rgba(12, 170, 204, 1);
            background-image: url('~/src/assets/images/AdminLogin/avatar.svg');
            background-size: cover;
        }

        .notify {
            width: 24px;
            height: 24px;
            background-image: url('~/src/assets/images/AdminLogin/notification.svg');
            background-size: cover;
            position: relative;
        }
        .active-notify::after {
            content: '';
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            top: 0;
            right: 0;
            border-radius: 50%;
            background-color: #ff0000;
        }
    }
}
