.card-project-item {
  width: 100%;
  height: 370px;
  // border: 1px solid #828282;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 1.4rem;
  overflow: hidden;

  .image-group {
    width: 100%;
    height: 180px;

    img {
      border-radius: 1.4rem;
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-project-detail {
    padding: 16px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: flex;
    height: calc(370px - 180px);
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-weight: 700;
      font-size: 16px;
      &:hover {
        cursor: pointer;
      }
    }

    .description {
      color: #ababab;
      font-weight: 400;
    }

    .investment-call {
      color: #39b54a;
    }
  }
}
