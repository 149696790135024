.wrapper#payment-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            margin-top: 24px;

            .content-top {
                width: 100%;
                display: flex;
                column-gap: 5%;

                .left-content {
                    width: 55%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h2 {
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 27px;
                    }

                    form {
                        .form-left-group {
                            display: flex;
                            flex-direction: column;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 25px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }

                            .form-group {
                                .input-7 {
                                    width: 100%;
                                    position: relative;
                                    height: 50px;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                    position: absolute;
                                    z-index: 1;
                                }
                            }
                        }

                        .form-right-group {
                            display: flex;
                            flex-direction: column;
                            height: 74px;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 10px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }

                            .form-group {
                                .select-group > div {
                                    border-radius: 18px;
                                    height: 50px;
                                    outline: none;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;

                                    > span:nth-child(2) {
                                        font-size: 1.6rem;
                                        line-height: 2.2rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }

                        .first {
                            margin-top: 14px;
                        }
                    }
                }

                .right-content {
                    width: 40%;
                    margin-top: 58px;

                    .course-details {
                        width: 100%;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        padding: 20px;

                        h2 {
                            font-weight: 600;
                            font-size: 2rem;
                            line-height: 2.4rem;
                        }

                        .image-title {
                            display: flex;
                            margin-top: 18px;

                            img {
                                width: 30%;
                            }

                            .right-group {
                                margin-left: 12px;
                                display: flex;
                                flex-direction: column;

                                .course-title {
                                    margin-top: 10px;
                                    font-weight: 600;
                                    font-size: 1.4rem;
                                    line-height: 1.8rem;
                                    color: #25aae1;
                                }
                            }
                        }

                        p {
                            margin-top: 10px;
                        }
                    }

                    .payment-method {
                        width: 100%;
                        margin-top: 24px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        padding: 20px;

                        h2 {
                            font-weight: 600;
                            font-size: 2rem;
                            line-height: 2.4rem;
                        }

                        .payment-card {
                            display: flex;
                            margin-top: 14px;
                            border: 1.5px solid #25aae1;
                            border-radius: 14px;
                            padding: 8px 16px;

                            span {
                                margin-left: 12px;
                            }
                        }
                    }
                }
            }

            .content-bottom {
                width: 100%;
                text-align: right;
                margin-top: 24px;

                button {
                    font-weight: 400;
                    font-size: 2rem;
                    line-height: 2.4rem;
                    background: #4285f4;
                    border-radius: 10px;
                    transition: 0.3s;
                    padding: 8px 24px;
                    color: #ffffff;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
