.company-wrapper {
  width: 100%;

  .company-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    padding: 0px 0px 48px 0px;

    .search-group {
      margin-top: 48px;
      margin-left: 136px;
    }

    .list-company {
      width: 100%;
      margin-top: 48px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 140px;
      padding-right: 140px;
      gap: 48px 2%;
    }
  }
}
