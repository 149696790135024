.container {
  display: flex;
  padding: 0 140px 48px;
  margin-top: 50px;
  column-gap: 50px;
  .see-more-news-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 24px;
      .see-more-news-item {
        width: 23.5%;
        height: auto;
        // margin: 5px;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .name-news {
          font-size: 1.7rem;
          cursor: pointer;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 2.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          visibility: visible;
        }

        .desc {
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #072d4b;
          display: -webkit-box;
          flex: 4 1;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.6rem;
          margin-top: 8px;
          opacity: 0.6;
          overflow: hidden;
          text-overflow: ellipsis;
          visibility: visible;
        }
        .time {
          color: #222121;
          flex: 1 1;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
          opacity: 0.4;
          padding-bottom: 8px;
          padding-top: 8px;
        }
      }
    }
  }
  .top-news-right {
    flex: 3;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    // margin-left: 100px;

    .advertise {
      display: flex;
      // justify-content: center;
      img {
        max-height: 665px;
        width: 100%;
      }
    }

    .most-watch {
      display: flex;
      flex-direction: column;
      // width: 300px;

      h2 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 4rem;
      }

      .list-news {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 32px;

        .news-item {
          display: flex;
          flex-direction: column;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 5px;
          border-radius: 3px;
          // row-gap: 10px;
          padding: 16px;
          .news-title:hover {
            cursor: pointer;
          }

          .bottom {
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: #072d4b;
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.title-category {
  display: flex;
  justify-content: center;
}
