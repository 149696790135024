.package-details {
    width: 100%;
    padding: 12px 20px;

    .top-content {
        border-bottom: 2px solid #ccc;
        margin-bottom: 24px;
    }

    .bottom-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .row {
            display: flex;
            font-size: 1.8rem;

            label {
                min-width: 15%;
            }

            .content-row {
                ul li {
                    list-style-type: circle;
                }

                .status {
                    color: chartreuse;
                }

                button {
                    padding: 2px 16px;
                    transition: 0.3s;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
