.create-course-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .ant-input-status-error {
        border-color: #ff4d4f;
    }

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .ant-picker.ant-picker-range {
            height: 50px;
            border-radius: 14px;
        }

        .content-main {
            width: 100%;
            height: auto;
            margin-top: 18px;

            .create-course-form {
                width: 100%;

                .form-wrapper {
                    .form-top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        column-gap: 20px;

                        .form-top-left {
                            width: 70%;

                            .form-top-left-group {
                                display: flex;
                                flex-direction: column;

                                label {
                                    width: fit-content;
                                    position: relative;
                                    top: 10px;
                                    left: 20px;
                                    color: rgba(37, 170, 225, 1);
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    background-color: #ffffff;
                                    padding: 0 4px;
                                    z-index: 2;
                                    overflow: visible;
                                }
                            }

                            .group-left {
                                display: flex;
                                width: 100%;
                                justify-content: space-between;

                                .start-date {
                                    width: 45%;
                                }

                                .end-date {
                                    width: 45%;
                                }
                            }

                            .form-group {
                                .select-group > div {
                                    border-radius: 18px;
                                    height: 50px;
                                    outline: none;
                                    // border: 1.5px solid #25aae1;
                                    border-radius: 14px;

                                    > span:nth-child(2) {
                                        font-size: 1.6rem;
                                        line-height: 2.2rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                                .input-7 {
                                    width: 100%;
                                    position: relative;
                                    height: 50px;
                                    // border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }
                            }
                        }

                        .form-top-right {
                            flex: 1;
                            .form-top-right-group {
                                display: flex;
                                flex-direction: column;

                                label {
                                    width: fit-content;
                                    position: relative;
                                    top: 10px;
                                    left: 20px;
                                    color: rgba(37, 170, 225, 1);
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    background-color: #ffffff;
                                    padding: 0 4px;
                                    z-index: 2;
                                    overflow: visible;
                                }

                                // &:nth-child(2) {
                                //     margin-top: 24px;
                                // }
                            }
                            .form-group {
                                .input-3 {
                                    width: 100%;
                                    height: 50px;
                                    // border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }

                                .select-group > div {
                                    border-radius: 18px;
                                    height: 50px;
                                    outline: none;
                                    // border: 1.5px solid #25aae1;
                                    border-radius: 14px;

                                    > span:nth-child(2) {
                                        font-size: 1.6rem;
                                        line-height: 2.2rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                }

                                .range-picker {
                                    margin-top: 12px;
                                }
                            }
                        }
                    }

                    .participate-group {
                        display: flex;
                        width: 70%;
                        justify-content: space-between;

                        .participate {
                            display: flex;
                            flex-direction: column;
                            width: 45%;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 10px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }

                            .form-group {
                                .input-3 {
                                    width: 100%;
                                    height: 50px;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }

                                .select-group > div {
                                    border-radius: 18px;
                                    height: 50px;
                                    outline: none;
                                    border-radius: 14px;

                                    > span:nth-child(2) {
                                        font-size: 1.6rem;
                                        line-height: 2.2rem;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }

                    .form-bottom {
                        .form-bottom-group {
                            display: flex;
                            flex-direction: column;

                            label {
                                width: fit-content;
                                position: relative;
                                top: 10px;
                                left: 20px;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }
                        }

                        .is-empty {
                            width: 170px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            h3 {
                                font-weight: 400;
                                color: #767676;
                                font-size: 14px;
                            }
                        }

                        .image-container {
                            display: flex;

                            .big-image {
                                height: 250px;
                                padding: 5px;
                                position: relative;

                                img {
                                    height: 100%;
                                }
                            }

                            .small-image-container {
                                display: flex;
                                width: auto;
                                height: 250px;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                flex-direction: column;
                                .small-image {
                                    height: 125px;
                                    width: 125px;
                                    padding: 5px;
                                    margin: 0 auto;
                                    position: relative;
                                    // overflow: hidden;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    border: 5px solid white;

                                    img {
                                        // height: min(1125px, 100%);
                                        width: 100%;
                                    }

                                    input[type='file'] {
                                        display: none;
                                    }

                                    &.upload {
                                        border: 1px dashed #c4c4c4;
                                        border-radius: 6px;

                                        label {
                                            width: 100%;
                                            height: 100%;
                                            padding: 20px 19px 16px;
                                            cursor: pointer;
                                            display: flex;
                                            justify-content: space-between;
                                            flex-direction: column;
                                            text-align: center;
                                        }
                                        h4 {
                                            color: #ababab;
                                        }

                                        img {
                                            width: 48px;
                                            height: 48px;
                                            margin: 0 auto;
                                        }
                                    }
                                }
                            }

                            .delete-image {
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border: 3px solid var(--hover-2);
                                z-index: 9;
                                right: -5px;
                                top: -5px;
                                border-radius: 50%;
                                text-align: center;
                                line-height: 15px;
                                color: var(--hover-2);
                                font-weight: 600;
                                cursor: pointer;
                                transition: 0.3s ease-in-out;
                                background-color: white;
                            }
                        }

                        .form-group {
                            width: 100%;

                            .input-textarea {
                                width: 100%;
                                position: relative;
                                min-height: 250px;
                                border-radius: 14px;
                                outline: none;
                                padding: 10px;
                                font-size: 1.6rem;
                                line-height: 3rem;
                            }
                        }
                    }

                    .action-submit {
                        width: 100%;
                        text-align: center;
                        margin-top: 18px;

                        button {
                            padding: 8px 24px;
                            background: #4285f4;
                            border-radius: 10px;
                            color: #ffffff;
                            transition: 0.3s;

                            &:hover {
                                cursor: pointer;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }
}
