.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            height: auto;
            // margin-top: 24px;

            .content-main-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 20px;

                .create-post-form {
                    width: 100%;

                    .form-wrapper {
                        .form-group-wrapper {
                            display: flex;
                            flex-direction: column;
                            row-gap: 12px;
                            margin-top: 12px;

                            .form-group {
                                .input-10 {
                                    width: 100%;
                                    position: relative;
                                    height: 50px;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }

                                .input-textarea {
                                    width: 100%;
                                    position: relative;
                                    min-height: 150px;
                                    border: 1.5px solid #25aae1;
                                    border-radius: 14px;
                                    outline: none;
                                    padding: 10px;
                                    font-size: 1.6rem;
                                    line-height: 3rem;
                                }
                            }

                            .search label {
                                width: fit-content;
                                position: relative;
                                top: -5px;
                                left: 0;
                                color: rgba(37, 170, 225, 1);
                                font-size: 1.2rem;
                                font-weight: 600;
                                background-color: #ffffff;
                                padding: 0 4px;
                                z-index: 2;
                                overflow: visible;
                            }
                        }

                        .select-group-wrapper {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            row-gap: 12px;

                            label {
                                margin-right: 12px;
                            }

                            > div {
                                max-width: 40%;
                            }

                            & > div > div {
                                border-radius: 18px;
                                height: 40px;
                                outline: none;
                                border: 1.5px solid #25aae1;
                                border-radius: 14px;

                                > span:nth-child(2) {
                                    font-size: 1.6rem;
                                    line-height: 2.2rem;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }

                        .upload-images {
                            margin-top: 24px;

                            h3 {
                                margin-bottom: 12px;
                            }

                            .main-form_item {
                                min-height: 68px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin-bottom: 24px;

                                &:last-child {
                                    padding-bottom: 20px;
                                }

                                label {
                                    text-transform: capitalize;
                                    color: var(--color-pri);
                                    font-weight: 700;
                                    font-size: 12px;
                                }

                                select {
                                    height: 44px;
                                    border: 1px solid #c4c4c4;
                                    padding-left: 16px;
                                    padding-right: 16px;
                                    border-radius: 4px;
                                }

                                input,
                                textarea {
                                    border: 1px solid #c4c4c4;
                                    border-radius: 4px;
                                    padding-left: 16px;
                                }

                                textarea {
                                    padding-top: 12px;
                                    min-height: 50px;
                                }

                                input {
                                    height: 44px;
                                }
                            }

                            .w100 {
                                width: 100%;
                            }

                            .delete-image {
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border: 3px solid var(--hover-2);
                                z-index: 9;
                                right: -5px;
                                top: -5px;
                                border-radius: 50%;
                                text-align: center;
                                line-height: 15px;
                                color: var(--hover-2);
                                font-weight: 600;
                                cursor: pointer;
                                transition: 0.3s ease-in-out;
                                background-color: white;

                                &:hover {
                                    border: 3px solid var(--color-pri);
                                    color: var(--color-pri);
                                }
                            }

                            .image-container {
                                display: flex;

                                .big-image {
                                    height: 250px;
                                    padding: 5px;
                                    position: relative;

                                    img {
                                        height: 100%;
                                    }
                                }

                                .small-image-container {
                                    display: flex;
                                    width: auto;
                                    height: 250px;
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                    flex-direction: column;

                                    .small-image {
                                        height: 125px;
                                        width: 125px;
                                        padding: 5px;
                                        margin: 0 auto;
                                        position: relative;
                                        // overflow: hidden;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: cover;
                                        border: 5px solid white;

                                        img {
                                            // height: min(1125px, 100%);
                                            width: 100%;
                                        }

                                        input[type='file'] {
                                            display: none;
                                        }

                                        &.upload {
                                            border: 1px dashed #c4c4c4;
                                            border-radius: 6px;

                                            label {
                                                width: 100%;
                                                height: 100%;
                                                padding: 20px 19px 16px;
                                                cursor: pointer;
                                                display: flex;
                                                justify-content: space-between;
                                                flex-direction: column;
                                                text-align: center;
                                            }

                                            h4 {
                                                color: #ababab;
                                            }

                                            img {
                                                width: 48px;
                                                height: 48px;
                                                margin: 0 auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .select-status-group {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            row-gap: 12px;
                            margin-top: 24px;

                            label {
                                margin-right: 12px;
                            }

                            > div {
                                max-width: 200px;
                            }

                            & > div > div {
                                border-radius: 18px;
                                height: 40px;
                                outline: none;
                                border: 1.5px solid #25aae1;
                                border-radius: 14px;

                                > span:nth-child(2) {
                                    font-size: 1.6rem;
                                    line-height: 2.2rem;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }

                        .action-submit {
                            width: 100%;
                            text-align: center;
                            margin-top: 16px;
                            .btn-submit {
                                width: fit-content;
                                padding: 10px 14px;
                                background: #4285f4;
                                border-radius: 10px;
                                outline: none;
                                border: none;
                                color: var(--white-color);
                                font-size: 2rem;
                                line-height: 2.4rem;
                                transition: 0.3s;

                                &:hover {
                                    cursor: pointer;
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
