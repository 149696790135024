.card-event-item {
  width: 31%;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 12px 12px;

  .image-group {
    width: 100%;
    height: 30vh;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
      overflow: hidden;
    }

    .area {
      position: absolute;
      top: 10px;
      background: #f7931a;
      color: #ffffff;
      padding: 2px;
    }
  }

  .card-event-content {
    padding: 16px 24px 32px 24px;

    .title {
      font-weight: 500;
      font-size: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      visibility: visible;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .date-group {
      margin-top: 16px;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
    }

    .category-group {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
    }

    .action-btn {
      margin-top: 16px;

      button {
        padding: 8px 24px;
        background: #019db4;
        font-size: 14px;
        border-radius: 30px;
        color: #ffffff;
        border: 1px solid #019db4;
        transition: ease 0.4s;

        &:hover {
          cursor: pointer;
          background: #ffffff;
          color: #019db4;
        }
      }
    }
  }
}
