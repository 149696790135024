.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  max-width: 130px;
  width: 90px;
  border-radius: 10px;
  border: 1px solid transparent;
  user-select: none;

  // +.wrapper {
  //     margin-left: 8px;
  // }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.icon + .title,
.title + .icon {
  margin-left: 8px;
}

//button type
.default1 {
  color: black;
}

.default1.business {
  min-width: 120px;
}

.primary {
  color: var(--white);
  border-color: currentColor;
  background-color: var(--primary);
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #158ec1;
  }
}

.primary2 {
  color: var(--white);
  // border-color: currentColor;
  background-color: var(--primary);
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #158ec1;
  }
}

.primary3 {
  margin-left: 70%;
  cursor: pointer;
  width: 100px;
  height: 25px;
  color: #ffffff;
  border-radius: 14px;
  background-color: #25aae1;
  border: none;
  font-size: 14px;
}

.primary4 {
  min-width: 616px;
  height: 55px;
  background: #25aae1;
  border-radius: 50px;
  outline: none;
  border-color: transparent;
  color: #ffffff;
  font-size: 2rem;
  line-height: 2.7rem;

  &:hover {
    cursor: pointer;
    background-color: #158ec1;
  }
}

.outline {
  color: var(--primary);
  border-color: currentColor;
  background-color: rgb(250, 246, 246);
  cursor: pointer;

  &:hover {
    background-color: rgb(235, 225, 225);
  }
}

.normal {
  color: var(--white);
  background-color: var(--primary);
  cursor: pointer;

  &:hover {
    background-color: #158ec1;
  }
}

//button sizes
.large {
  min-width: 230px;
  padding: 12px 14px;
}

.small {
  min-width: 130px;
  padding: 8px 12px;
}

.small2 {
  padding: 8px 12px;
  width: fit-content;
}
