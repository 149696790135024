.container-album {
    display        : flex;
    flex-wrap      : wrap;
    width          : 85%;
    height         : auto;
    gap            : 20px;
    justify-content: space-around;

    .item-album {
        box-shadow    : rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius : 10px;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        height        : auto;

        img {
            border-radius: 10px 10px 0px 0px;
            width        : 450px;
        }

        p {
            font-size : 20px;
            margin-top: 10px;
            color     : #222121;

        }

        button {
            color        : #ffffff;
            background   : #339999;
            border-radius: 25px;
            margin-bottom: 25px;
            height       : 40px;
        }
    }

    .container-image {
        .image-details {
            border-radius: 10px;

            img {
                width        : 330px;
                border-radius: 10px;
            }
        }
    }

}


// .list-album-wrapper {
//     width: 100%;
//     display: flex;
//     flex-direction: column;

//     .album-row {
//         display: flex;
//         flex-direction: column;
//         row-gap: 12px;
//         width: 100%;

//         .top-album {
//             display: flex;
//             justify-content: space-between;

//             .album-title {
//                 font-weight: 600;
//                 font-size: 2rem;
//                 line-height: 2.4rem;
//             }

//             .see-detail {
//                 background: #ffffff;
//                 color: rgb(13, 139, 170);

//                 &:hover {
//                     cursor: pointer;
//                 }
//             }
//         }

//         .image-group {
//             width: 100%;
//             display: flex;
//             border: 1px solid #828282;
//             border-radius: 10px;
//             padding: 20px;
//             column-gap: 1%;

//             .image-item {
//                 width: 20%;
//                 height: 180px;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                     object-fit: cover;
//                 }
//             }
//         }
//     }
// }