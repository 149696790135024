.wrapper {
  width: 100%;

  .slider-group {
    width: 100%;
    height: 400px;
    .image-group {
      width: 100%;

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }

    .slick-arrow.slick-next {
      display: none !important;
    }
  }

  .container {
    margin-top: 0px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
