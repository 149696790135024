.wrapper {
  width: 100%;

  .slideshow {
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
      overflow: hidden;
    }

    .slide-detail {
      width: 100%;
      background-size: cover;
    }
  }

  .container {
    margin-top: 0px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
