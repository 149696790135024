.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            height: auto;
            margin-top: 10px;

            .content-top {
                display: flex;
                justify-content: space-between;
                height: 100%;

                .course-details {
                    display: flex;
                    column-gap: 10px;
                    width: 80%;

                    .course-images {
                        width: 30%;
                        max-height: 150px;

                        img {
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    .course-info {
                        display: flex;
                        flex-direction: column;
                        row-gap: 14px;
                        flex: 1;

                        .course-info-top {
                            display: flex;
                            flex-direction: column;
                            row-gap: 6px;

                            .course-title {
                                font-size: 1.6rem;
                                line-height: 1.9rem;
                                font-weight: 600;
                            }

                            .course-info-list {
                                display: flex;
                                flex-direction: column;
                                flex-wrap: wrap;
                                max-height: 80px;
                                column-gap: 20px;

                                .course-details-item {
                                    max-width: 250px;
                                }
                            }
                        }

                        .course-info-bottom {
                            span {
                                font-size: 1.8rem;
                                line-height: 2.2rem;
                                font-weight: 700;
                                color: #094b72;
                            }
                        }
                    }
                }

                .action {
                    .action-delete {
                        display: flex;
                        column-gap: 10px;
                        justify-content: flex-end;
                        align-items: center;
                        color: rgba(251, 37, 37, 1);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .content-bottom {
                width: 100%;
                height: auto;
                margin-top: 10px;

                h3 {
                    margin-top: 42px;
                    font-size: 2.4rem;
                }

                .course-desc {
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: 1.8rem;
                }
            }
        }
    }
}
