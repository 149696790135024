.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 100px 140px 140px;
  gap: 30px;

  .switch {
    display: flex;
    padding-top: 50px;
    justify-content: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 28px;
    gap: 100px;
    cursor: pointer;

    .list-item {
      font-size: 28px;
      color: #019DB4;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .abc {
      font-size: 28px;
    }
  }



  .title {
    display: flex;
    width: 100%;
    height: 64px;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    gap: max(15%, 200px);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
}