.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-admin {
        width: 100%;
        background-color: #ccc;
    }

    .container {
        width: 100%;
        height: auto;
        display: flex;

        .left-content {
            width: 300px;
            min-height: 500px;
            border-right: 1px solid rgba(171, 171, 171, 1);
        }

        .right-content {
            width: calc(100% - 300px);
            height: auto;
        }
    }
}
