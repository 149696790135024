.overview-project-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
    }
}
