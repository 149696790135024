.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;
    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .main-content {
            margin-top: 22px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;

            .course-group {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                width: 30%;
                padding: 8px;
                box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
                border-radius: 8px;

                .course-image {
                    width: 100%;
                    height: 55%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                }

                .course-name {
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    font-weight: 600;
                    height: 12%;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    visibility: visible;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .course-details {
                    .course-details-item {
                        display: flex;
                        column-gap: 20px;
                        align-items: center;
                    }
                }

                .course-price {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    font-weight: 700;
                    color: #094b72;
                    display: flex;
                    justify-content: space-between;

                    svg {
                        margin-right: 10px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .group {
                        color: rgb(13, 113, 226);
                    }

                    .delete {
                        color: #ff0000;
                    }
                }
            }
        }

        .pagination-group {
            margin-top: 24px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
