.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            height: auto;

            .list-courses {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                row-gap: 12px;

                .course-item {
                    display: flex;
                    border-bottom: 0.5px dashed #39b54a;
                    padding: 12px 0;

                    .course-image {
                        background-color: #ccc;
                        img {
                            width: 180px;
                            height: 180px;
                        }
                    }

                    .course-details {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .left-content {
                            flex: 1 1;
                            height: 100%;
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            row-gap: 8px;

                            .course-name {
                                font-weight: 500;
                                font-size: 2rem;
                                line-height: 2.4rem;
                            }

                            .row-group {
                                font-weight: 500;
                                font-size: 2rem;
                                line-height: 2.4rem;
                                color: rgba(41, 45, 50, 0.5);

                                label {
                                    display: inline-block;
                                    min-width: 150px;
                                }
                            }
                        }

                        .right-content {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .love-icon {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;

                                svg {
                                    font-size: 2.4rem;
                                    fill: rgba(217, 217, 217, 1);

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }

                            .love-icon.active {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;

                                svg {
                                    font-size: 2.4rem;
                                    fill: rgba(251, 37, 37, 1);

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }

                            .actions-group {
                                display: flex;
                                flex-direction: column;
                                row-gap: 4px;

                                span {
                                    font-weight: 600;
                                    font-size: 1.4rem;
                                    line-height: 1.8rem;
                                }

                                button {
                                    color: #ffffff;
                                    width: 100px;
                                    height: 40px;
                                    background: #4285f4;
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                                    border-radius: 10px;
                                    transition: 0.3s;

                                    &:hover {
                                        cursor: pointer;
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
