.my-program-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;

            .index-group {
                width: 100%;
                margin-top: 18px;

                h3 {
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.8rem;
                }

                .list-index {
                    display: flex;
                    column-gap: 24px;
                    margin-top: 12px;

                    .index-item {
                        flex: 2.5;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        padding: 10px;

                        .title {
                            font-weight: 600;
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                        }

                        .number {
                            font-weight: 600;
                            font-size: 1.4rem;
                            line-height: 1.8rem;
                            text-align: right;
                        }
                    }
                }
            }

            .program-group {
                width: 100%;
                margin-top: 24px;

                .program-title {
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.8rem;
                }

                .search-group {
                    margin-top: 12px;
                    display: flex;
                    justify-content: space-between;

                    .search-input {
                        min-width: 80%;
                        height: 50px;

                        #search-business {
                            min-width: 100%;
                            height: 50px;
                            border: 1.5px solid #25aae1;
                            border-radius: 14px;
                            outline: none;
                            padding-left: 10px;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                        }
                    }

                    .btn-search {
                        width: 17%;
                        height: 50px;
                        background: rgb(37, 170, 225);
                        border-radius: 14px;
                        border-color: transparent;
                        color: #ffffff;
                        font-size: 16px;
                        transition: 0.3s;

                        &:hover {
                            cursor: pointer;
                            background-color: rgb(37, 170, 225, 0.8);
                        }
                    }
                }

                .program-table {
                    margin-top: 18px;
                }
            }
        }
    }
}
