.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .inner {
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 999;

    .container {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: rgba(37, 170, 225, 0.5);
      height: 90px;

      .contents {
        display: flex;
        width: 100%;

        .logo-content {
          width: 40%;
          padding-left: 50px;
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          a {
            width: 100%;
            background: #0075bf;
            border-radius: 18px;
            width: fit-content;
            padding: 8px 24px;
            margin-left: 10%;
            color: #ffffff;
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }

        .title-content {
          justify-content: right;
          padding-right: 50px;
          align-items: center;
          display: flex;
          width: 90%;
          gap: 15px;
          font-size: 16 px;
          width: 80%;

          .border-split {
            font-family: "Courier New", Courier, monospace;
            font-size: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fafafa;
          }

          .title-detail {
            cursor: pointer;
            text-decoration: none;

            :hover {
              color: #3e4345;
            }

            ::after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              background: #25aae1;
              transition: width 0.7s;
            }

            // p:hover::after {
            //   width: 100%;
            //   border-bottom: 3px solid #25aae1;
            // }

            .title-detail-link {
              color: #ffff;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              border-bottom: 3px solid red;

              // p {
              //     margin-top: 1em;
              // }
            }
          }
        }
      }
    }
  }
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: unset;
}
