.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }

            .header-search {
                display: flex;
                justify-content: space-between;

                .search-input {
                    width: 80%;
                    height: 50px;

                    #search-business {
                        min-width: 100%;
                        height: 50px;
                        border: 1.5px solid #25aae1;
                        border-radius: 14px;
                        outline: none;
                        padding-left: 10px;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                    }
                }

                .btn-search {
                    min-width: 17%;
                    height: 50px;
                    background: rgb(37, 170, 225);
                    border-radius: 14px;
                    border-color: transparent;
                    color: #ffffff;
                    font-size: 16px;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(37, 170, 225, 0.8);
                    }
                }
            }

            .header-create-group {
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: flex-end;

                .btn-create-group {
                    min-width: 17%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #25aae1;
                    border-radius: 8px;
                    border: transparent;
                    color: #ffffff;
                    padding: 5px 12px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        margin-right: 12px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(37, 170, 225, 0.8);
                    }
                }
            }
        }

        .main-content {
            margin-top: 22px;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 26px;

            .group-group {
                display: flex;
                column-gap: 26px;
                min-width: 100%;
                height: 150px;

                .group-info {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .group-details {
                        max-width: 776px;
                        display: flex;
                        flex-direction: column;
                        row-gap: 16px;
                        padding: 6px;

                        .group-details-top {
                            display: flex;
                            flex-direction: column;
                            row-gap: 10px;

                            .group-name {
                                font-size: 2.4rem;
                                line-height: 2.4rem;
                                font-weight: var(--font-weight-600);
                                transition: 0.3s;

                                &:hover {
                                    cursor: pointer;
                                    color: #25aae1;
                                }
                            }

                            .group-member {
                                font-size: 1.6rem;
                                line-height: 1.6rem;
                                font-weight: 400;
                                color: rgba(171, 171, 171, 1);
                            }
                        }

                        .group-desc {
                            width: 100%;
                            min-height: 70px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        }
                    }

                    .btn-participation {
                        width: 116px;
                        height: 44px;
                        background: #25aae1;
                        border-color: transparent;
                        outline: none;
                        border-radius: 10px;
                        font-size: 2rem;
                        line-height: 2.4rem;
                        color: #ffffff;

                        &:hover {
                            cursor: pointer;
                            background-color: #158ec1;
                        }
                    }
                }
            }
        }
    }
}
