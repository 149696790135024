.card-member-item {
    width: 20%;
    padding-right: 2%;
    margin-left: 2%;
    border-radius: 10px;
    background-color: #f6feff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:last-child {
        border-right: none;
    }

    .image_group {
        border-radius: 50%;
        margin-bottom: 16px;

        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .member-detail {
        .name {
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.2rem;
        }

        .position {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: #514f4f;
            margin-top: 8px;
            text-align: center;
        }

        .action-btn {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            button {
                background: #ffffff;
                border: 1px solid #019DB4;
                border-radius: 30px;
                padding: 8px 24px;
                font-size: 14px;
                color: #019DB4;
                transition: ease 0.4s;

                &:hover {
                    cursor: pointer;
                    color: #ffffff;
                    background: #019DB4;
                }
            }
        }
    }
}