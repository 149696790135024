.card-wrapper {
  width: 18.95%;
  border-radius: 14px;
  // border: 1px solid #ababab;
  //   box-shadow: 0px -12px 5px rgba(66, 62, 62, 0.01),
  //     0px -7px 4px rgba(66, 62, 62, 0.05), 0px -3px 3px rgba(66, 62, 62, 0.09),
  //     0px -1px 2px rgba(66, 62, 62, 0.1), 0px 0px 0px rgba(66, 62, 62, 0.1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  transition: 0.3s;

  &:hover {
    transform: translateY(-10px);
  }

  .card-image {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .card-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;

    .card-details-title {
      font-weight: 600;
      height: 36px;
      font-size: 1.6rem;
      line-height: 1.8rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      visibility: visible;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }
    }

    .rating {
      display: flex;
      column-gap: 6px;
      font-size: 2rem;
    }

    .price {
      .price-title {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.8rem;
      }

      .price-number {
        color: red;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.8rem;
      }
    }
  }
}
