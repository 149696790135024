.order-manage#order-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }

            .action-header {
                .ant-tabs-nav-list {
                    background-color: #edeff1;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .ant-tabs-tab {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }
}
