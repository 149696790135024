.main-content {
    margin-top: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .post-group {
        display: flex;
        align-items: center;
        min-width: 100%;
        // height: 134px;
        column-gap: 18px;
        background: #fafafa;

        .post-avatar {
            flex: 1.5;
            display: flex;
            padding-left: 18px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.8rem;
        }

        .post-time {
            flex: 2;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.8rem;
            padding: 14px;
        }

        .post-title {
            flex: 2;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: #4285f4;
            padding: 14px;
        }
        .post-type {
            flex: 1.5;
            display: flex;
            justify-content: center;
            padding: 14px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.8rem;
        }
        .post-status {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            row-gap: 12px;
            padding-right: 18px;
            padding: 14px;
            font-size: 2rem;
            column-gap: 12px;

            .comfirm {
                color: #369313;

                &:hover {
                    cursor: pointer;
                }
            }

            .uncomfirm {
                color: #fb2525;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
