.question-form {
    width: 100%;
    padding: 10px 0;

    .question-form__header {
        width: 100%;
        font-weight: 600;
        font-size: 2rem;
        font-weight: 2.8rem;
        border-bottom: 3px solid #ccc;
        padding-bottom: 4px;
    }

    .question-form__content {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        margin-top: 24px;

        .header-group {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.8rem;
            padding: 0 24px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        }

        .form-details {
            width: 100%;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            .form-details__header {
                width: 100%;
                background-color: #e3e3e3;
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 2rem;
                padding: 8px 24px;
            }

            .form-details__content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .row {
                    display: flex;
                    width: 100%;

                    .col {
                        width: 50%;

                        .form-group {
                            border: 1px solid #ccc;
                            height: 50px;
                            padding-left: 24px;
                            padding-right: 24px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            label {
                                width: 50%;
                                span {
                                    color: #ff0000;
                                }
                            }

                            input {
                                width: 100%;
                                display: flex;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}
