.register-consultant {
    .send-btn {
        display: flex;
        justify-content: flex-end;
    }
}

.ant-modal-content {
    border-radius: 12px;
}

.ant-modal-header {
    padding: 48px 48px 24px 48px;
    border-radius: 12px;
}

.ant-modal-title {
    font-size: 20px !important;
}

.ant-modal-body {
    padding: 24px 48px 64px 48px;
}