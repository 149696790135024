.project-detail-wrapper {
  .project-detail-container {
    padding: 40px 140px;
    border-bottom: 1px solid #ddd;
  }

  .list-project-wrapper {
    padding: 40px 140px;

    h2 {
      font-weight: 700;
      margin-bottom: 26px;
    }
  }
}
