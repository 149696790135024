.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 50px;
    gap: 20px;

    .inner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        .container-router {
            display: flex;
            gap: 5px;


            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #25AAE1;
            }
        }

        .contaier-status {
            display: flex;
            width: 100%;

            .status-left {
                width: max(80%, 700px);
                // background-color: red;
                display: flex;
                flex-direction: row;
                margin-left: 5px;
                gap: 10px;

                p {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #6C798F;
                }

                .left1 {
                    width: max(10%, 100px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-right: 1px solid #EEEEEE;
                }

                .left2 {
                    width: max(20%, 100px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-right: 1px solid #EEEEEE;
                }

                .left3 {
                    width: max(12%, 100px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-right: 1px solid #EEEEEE;
                }

                .left4 {
                    width: max(20%, 100px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-right: 1px solid #EEEEEE;
                }

                .left5 {
                    width: max(20%, 100px);
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    // border-right: 1px solid #EEEEEE;
                }
            }

            .status-right {
                width: max(22%, 150px);
                display: flex;
                gap: 10px;
            }
        }

        .contaier-btn {
            display: flex;
            width: 100%;
            padding-top: 40px;

            .detail-left {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .detail-left1 {
                    // min-height: 270px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;

                    .detail-left1__title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        padding: 16px;
                    }

                    .detail-left1__des {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        padding: 16px;

                        .des-1 {
                            width: 23%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            p {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .des-2 {
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            p {
                                color: #006889;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .des-3 {
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            p {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                    }
                }

                .detail-left2 {
                    // min-height: 270px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;

                    .detail-left1__title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        padding: 16px;
                    }

                    .detail-left1__desproduct {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        padding: 16px;

                        .des-1 {
                            width: 23%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            p {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .des-2 {
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            padding-left: 20px;


                            p {
                                color: #006889;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .des-3 {
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            padding-left: 20px;


                            p {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                    }
                }
            }

            .detail-right {
                width: 30%;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 130px;

                p {
                    padding: 15px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                }

            }
        }
    }
}