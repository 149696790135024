.card-story-item {
  width: 32%;
  display: flex;
  flex-direction: column;
  // align-items: ;
  column-gap: 24px;
  min-height: 140px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  // padding: 16px;
  border-radius: 12px;
  // border-radius: 1px;

  .card-story-left {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 16px;
    .title-story {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      // flex: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      visibility: visible;
      &:hover {
        cursor: pointer;
      }
    }

    .des {
      margin-top: 8px;
      flex: 4;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #072d4b;
      opacity: 0.6;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      visibility: visible;
    }

    .card-bottom {
      flex: 1;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      opacity: 0.4;
      padding-bottom: 8px;
      padding-top: 8px;
      color: #222121;
      .time {
      }
    }
  }

  img {
    width: 100%;
    // height: 160px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
