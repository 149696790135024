.modal-style .ant-modal-content {
  border-radius: 20px;
  border: #25aae1 2px solid;
}

.modal-style .ant-modal-body {
  padding: 0px;

  .ant-tabs-nav-wrap {
    width: 100%;

    .ant-tabs-nav-list {
      border: none;
      width: 100%;
      padding: 10px;
      justify-content: center;

      .ant-tabs-tab {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        font-weight: 400;
        font-size: 24px;
        background-color: #fff;
      }
    }
  }
}

.modal-style .ant-modal-footer {
  padding: 20px 36px 36px;
  display: flex;
  justify-content: right;
  gap: 10px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #019db4 !important;
}
