.marketing-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            height: auto;
            margin-top: 24px;

            .list-marketing {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 30px;

                .marketing-item {
                    width: 23%;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    .icon-group {
                        width: 80px;
                        height: 80px;
                        background: #25aae1;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 10px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .advertise {
                        margin-top: 10px;
                        font-weight: 600;
                        font-size: 1.4rem;
                        line-height: 1.8rem;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 1.2rem;
                        line-height: 1.6ren;
                        padding: 10px;
                    }
                }
            }
        }
    }
}
