.list-contact-wrapper {
    padding: 20px;

    .expanded-contact {
        .top-expanded {
            border-bottom: 1px solid #ddd;
            padding-bottom: 16px;

            .row-group {
                display: flex;
                flex-direction: column;
                row-gap: 24px;
            }
        }

        .bottom-expanded {
            margin-top: 16px;
        }
    }
}
