.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-left: 50px;
  gap: 20px;

  .content-item {
    display: flex;

    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    gap: 200px;
    cursor: pointer;
  }

  .content-detail {
    .contact {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .contact-detail {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
      }

      .contact-btn {
        display: flex;
        gap: 80px;
      }
    }
  }

  .list-item {
    color: #019db4;
  }
  .list-items {
    color: #838383;
  }
}
