.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;

            .images-group {
                .image-grid {
                    --gap: 16px;
                    --num-cols: 4;
                    --row-height: 400px;

                    box-sizing: border-box;
                    padding: var(--gap);

                    display: grid;
                    grid-template-columns: repeat(var(--num-cols), 1fr);
                    grid-auto-rows: var(--row-height);
                    gap: var(--gap);
                }

                .image-grid > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .image-grid-col-2 {
                    grid-column: span 2;
                }

                .image-grid-row-2 {
                    grid-row: span 2;
                }

                @media screen and (max-width: 1024px) {
                    .image-grid {
                        --num-cols: 2;
                        --row-height: 200px;
                    }
                }
            }
        }
    }
}
