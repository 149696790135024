.eventForm {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .main-form {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 16px;
        margin-bottom: 24px;

        &__title {
            padding-bottom: 26px;
            font-weight: 700;
            font-size: 14px;
        }

        &__item {
            min-height: 68px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 24px;

            &:last-child {
                padding-bottom: 20px;
            }

            label {
                text-transform: capitalize;
                color: var(--color-pri);
                font-weight: 700;
                font-size: 12px;
            }

            select {
                height: 44px;
                border: 1px solid #c4c4c4;
                padding-left: 16px;
                padding-right: 16px;
                border-radius: 4px;
            }

            input,
            textarea {
                border: 1px solid #c4c4c4;
                border-radius: 4px;
                padding-left: 16px;
            }

            textarea {
                padding-top: 12px;
                min-height: 50px;
            }

            input {
                height: 44px;
            }
        }

        .w50 {
            width: max(45%, 370px);
        }

        .w100 {
            width: 100%;
        }

        .w25 {
            width: max(23%, 180px);
        }

        .delete-image {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid var(--hover-2);
            z-index: 9;
            right: -5px;
            top: -5px;
            border-radius: 50%;
            text-align: center;
            line-height: 15px;
            color: var(--hover-2);
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            background-color: white;

            &:hover {
                border: 3px solid var(--color-pri);
                color: var(--color-pri);
            }
        }

        .image-container {
            display: flex;

            .big-image {
                height: 250px;
                padding: 5px;
                position: relative;

                img {
                    height: 100%;
                }
            }

            .small-image-container {
                display: flex;
                width: auto;
                height: 250px;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-direction: column;

                .small-image {
                    height: 125px;
                    width: 125px;
                    padding: 5px;
                    margin: 0 auto;
                    position: relative;
                    // overflow: hidden;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: 5px solid white;

                    img {
                        // height: min(1125px, 100%);
                        width: 100%;
                    }

                    input[type='file'] {
                        display: none;
                    }

                    &.upload {
                        border: 1px dashed #c4c4c4;
                        border-radius: 6px;

                        label {
                            width: 100%;
                            height: 100%;
                            padding: 20px 19px 16px;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            text-align: center;
                        }

                        h4 {
                            color: #ababab;
                        }

                        img {
                            width: 48px;
                            height: 48px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .button-action-save {
        position: absolute;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 44.5px;
        right: 0;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        background-color: #006889;
    }
}
