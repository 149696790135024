.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    gap: 20px;

    .content1 {
        // width: 50%;
        width: 200px;
        height: 200px;
    }

    .content2 {
        // width: 50%;
        // background-color: red;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
            font-weight: 600;
            font-size: 36px;
            line-height: 38px;
        }

        .des {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: #828282;
            width: 500px;
        }
    }
}
