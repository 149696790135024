.wrapper {
    width: 100%;
    height: 100vh;
    background-image: url('~/src/assets/images/login/staff/logobanner.jpg');
    background-size: cover;

    .children {
        display: block;
        justify-content: center;
        align-items: center;
        z-index: 1;
        width: 100%;
        top: 50%;
    }
}
