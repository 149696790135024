.inner {
  .detail {
    margin-top: 5px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    padding: 24px 24px 32px 24px;

    .content1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .title {
        display: flex;
        font-weight: 500;
        font-size: 2rem;
        line-height: 29px;
      }

      .des {
        margin-bottom: 16px;
        display: flex;
        color: #838383;
        font-size: 1.6rem;
        font-weight: 300;
      }
    }

    .content2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-weight: 300;
        display: flex;
        font-weight: 500;
        font-size: 2rem;
        line-height: 18px;
        margin-bottom: 8px;
      }

      .des {
        font-size: 1.6rem;
        line-height: 14px;
        display: flex;
        color: #838383;
        font-weight: 300;
      }
    }

    .btn {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      gap: 80px;

      .reg-btn {
        color: #019DB4;
        border: 1px solid #019DB4;

        &:hover {
          background-color: #019DB4;
          color: white;
        }
      }


    }
  }

  .contents {
    background-color: #fafafa;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    display: flex;

    .content-img {
      flex-shrink: 0;

      img {
        border-radius: 14px 0 0 14px;
        max-width: 280px;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    .content-workshop {
      padding: 16px 24px 32px 24px;
      display: flex;
      flex-direction: column;

      .content-des {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 24px;
        color: black;
        margin-bottom: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      .acb {
        margin-top: auto;

        .content-time-place {
          font-size: 1.6rem;
          color: black;
          line-height: 14px;

          span {
            color: #838383;
            font-weight: 400;
          }
        }


        .content-btn1 {
          margin-bottom: 8px;
          font-size: 1.6rem;
          line-height: 16px;
          color: black;

          span {
            color: #838383;
            font-weight: 400;
          }
        }
      }


      .content-btn2 {
        margin-bottom: 16px;
        font-size: 14px;

        .status-btn.end {
          background: #e7fcff;
          border-radius: 12px;
          width: -moz-fit-content;
          width: fit-content;
          padding: 8px 16px;
          border: 1px solid #ddd;
          color: red;
        }

        .status-btn.in-progress {
          padding: 8px 16px;
          background: #e7fcff;
          border-radius: 12px;
          width: 120px;
          border: 1px solid #ddd;
          color: green;
        }

        .status-btn.upcoming {
          // background: #e0e0e0;
          border: 1px solid #ddd;
          border-radius: 12px;
          background: #e7fcff;
          padding: 8px 16px;
          width: 120px;
          color: #838383;
        }
      }
    }

  }
}