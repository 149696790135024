.company-detail-wrapper {
  width: 100%;

  .company-detail-inner {
    width: 100%;
    padding: 48px 140px 48px 140px;
  }

  .company-project {
    width: 100%;
    margin-top: 48px;

    h2 {
      font-weight: 700;
      font-size: 2.4rem;
      margin-bottom: 4px;
    }

    .list-project {
      padding-top: 16px;
      display: flex;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 24px;
      // gap: 22px;
    }
  }

  .company-products {
    margin-top: 48px;
    width: 100%;

    h2 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 40px;
      margin-bottom: 16px;
    }

    .list-product {
      width: 100%;
      gap: 24px;
      margin-top: 2px;
      // overflow: hidden !important;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // gap: 20px 10px;
      padding: 0px 0;
    }
  }
}
