.wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .trash-icon:hover {
        cursor: pointer;
    }

    .edit-icon:hover {
        cursor: pointer;
    }

    .inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }
        }

        .content-main {
            width: 100%;
            margin-top: 16px;

            .content-top {
                width: 100%;
                display: flex;
                flex-direction: column;

                .group-title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-right: 16px;
                    .title {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        font-weight: 400;
                        padding-bottom: 16px;
                        padding-left: 16px;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 90px;
                            border-bottom: 1px solid #006889;
                            bottom: 0;
                            left: 16px;
                        }
                    }
                    .action-group {
                        display: flex;
                        column-gap: 20px;

                        .btn-delete {
                            display: flex;
                            column-gap: 6px;
                            align-items: center;
                            padding: 10px;
                            background: #fb2525;
                            border-radius: 4px;
                            color: #ffffff;
                            transition: 0.3s;

                            &:hover {
                                cursor: pointer;
                                opacity: 0.7;
                            }
                        }

                        .btn-order {
                            display: flex;
                            column-gap: 6px;
                            align-items: center;
                            padding: 10px;
                            background: rgb(66, 133, 244);
                            border-radius: 4px;
                            color: #ffffff;
                            transition: 0.3s;

                            &:hover {
                                cursor: pointer;
                                background: rgba(66, 133, 244, 0.8);
                            }
                        }
                    }
                }

                .filter-search-group {
                    width: 100%;
                    height: 76px;
                    min-height: 76px;
                    display: flex;
                    padding: 16px;
                    justify-content: space-between;

                    .filter {
                        width: 15%;
                        max-width: 15%;
                        height: 44px;
                        border: 1px solid #ababab;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px;

                        span {
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            font-weight: 400;
                        }
                    }

                    .search {
                        width: 83%;
                        max-width: 83%;
                        height: 44px;
                        border: 1px solid #ababab;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 8px 16px;

                        input {
                            width: 97%;
                            font-size: 1.2rem;
                            line-height: 2rem;
                        }
                    }
                }
            }

            .content-bottom {
                width: 100%;
                padding: 16px;
            }
        }
    }
}
