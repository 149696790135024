.wrapper {
    background: var(--white-color);
    padding: 15px 0px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    gap: 12px;
    border: 1px solid #4285f4;

    span {
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;

        svg {
            font-size: 1.6rem;
            min-width: 20px;
        }
    }
}
