.create-project-wrapper {
    padding: 20px;

    .is-empty {
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-weight: 400;
            color: #767676;
            font-size: 14px;
        }
    }

    .image-container {
        display: flex;

        .big-image {
            height: 250px;
            padding: 5px;
            position: relative;

            img {
                height: 100%;
            }
        }

        .small-image-container {
            display: flex;
            width: auto;
            height: 250px;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-direction: column;
            .small-image {
                height: 125px;
                width: 125px;
                padding: 5px;
                margin: 0 auto;
                position: relative;
                // overflow: hidden;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 5px solid white;

                img {
                    // height: min(1125px, 100%);
                    width: 100%;
                }

                input[type='file'] {
                    display: none;
                }

                &.upload {
                    border: 1px dashed #c4c4c4;
                    border-radius: 6px;

                    label {
                        width: 100%;
                        height: 100%;
                        padding: 20px 19px 16px;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        text-align: center;
                    }
                    h4 {
                        color: #ababab;
                    }

                    img {
                        width: 48px;
                        height: 48px;
                        margin: 0 auto;
                    }
                }
            }
        }

        .delete-image {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid var(--hover-2);
            z-index: 9;
            right: -5px;
            top: -5px;
            border-radius: 50%;
            text-align: center;
            line-height: 15px;
            color: var(--hover-2);
            font-weight: 600;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            background-color: white;
        }
    }
}
