.content-brand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-detail {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .banner {
        width: 100%;
    }

    .sologan {
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        top: 15%;

        .name {
            line-height: 60px;
            font-size: 64px;
            margin: 8px;
            background: linear-gradient(180deg, #ffe600 0%, rgba(120, 237, 101, 0.97) 50%, #4285f4 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        span {
            color: #fff;
            font-size: 18px;
        }
    }
}
