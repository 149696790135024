.content-wrapper {
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 20px;

    .content-inner {
        width: 100%;
        height: 100%;

        .header-main {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .header-title {
                display: flex;
                column-gap: 8px;
                align-items: center;
                font-size: 1.4rem;
                color: var(--primary-color-admin);

                svg {
                    height: 10px;
                    width: 10px;
                    margin-top: 2px;
                }
            }

            .header-search {
                display: flex;
                justify-content: space-between;

                .search-input {
                    min-width: 80%;
                    height: 50px;

                    #search-business {
                        min-width: 100%;
                        height: 50px;
                        border: 1.5px solid #25aae1;
                        border-radius: 14px;
                        outline: none;
                        padding-left: 10px;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                    }
                }

                .btn-search {
                    width: 17%;
                    height: 50px;
                    background: rgb(37, 170, 225);
                    border-radius: 14px;
                    border-color: transparent;
                    color: #ffffff;
                    font-size: 16px;
                    transition: 0.3s;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(37, 170, 225, 0.8);
                    }
                }
            }

            .header-create-group {
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: flex-end;

                .btn-create-group {
                    min-width: 17%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #25aae1;
                    border-radius: 8px;
                    border: transparent;
                    color: #ffffff;
                    padding: 5px 12px;
                    transition: 0.3s;

                    svg {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        margin-right: 12px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(37, 170, 225, 0.8);
                    }
                }
            }
        }

        .main-content {
            margin-top: 22px;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 26px;

            .business-group {
                display: flex;
                column-gap: 26px;
                min-width: 100%;
                height: 150px;
                padding: 4px 16px;
                border-radius: 10px;
                border: 1px solid #0caacc;

                .business-avatar {
                    width: 150px;
                    height: 140px;
                    display: flex;
                    align-items: center;

                    img {
                        max-width: 150px;
                        max-height: 140px;
                    }
                }

                .business-info {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;

                    .business-details {
                        max-width: 740px;
                        display: flex;
                        flex-direction: column;
                        row-gap: 6px;
                        padding: 6px;

                        .business-name {
                            font-size: 2.4rem;
                            line-height: 2.8rem;
                            font-weight: var(--font-weight-600);
                        }

                        .business-address {
                            font-size: 1.6rem;
                            line-height: 2.8rem;
                            font-weight: 400;
                            height: 52px;
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .business-website {
                            font-size: 1.6rem;
                            line-height: 1.8rem;
                            color: #0caacc;
                        }

                        .business-email {
                            font-size: 1.6rem;
                            line-height: 1.8rem;
                        }
                    }

                    .business-action {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .action-delete {
                            display: flex;
                            flex-direction: column;
                            row-gap: 10px;
                            justify-content: center;
                            align-items: flex-start;
                            color: rgba(251, 37, 37, 1);

                            &:hover {
                                cursor: pointer;
                            }

                            .btn-group {
                                display: flex;
                                column-gap: 10px;
                                align-items: center;
                            }

                            .btn-group.edit {
                                color: rgb(94, 151, 9);
                            }
                        }

                        .business-phone {
                            display: flex;
                            column-gap: 10px;
                            justify-content: flex-end;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 24px;
        }
    }
}
