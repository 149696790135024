.card-infor-company {
  width: 100%;
  display: flex;
  column-gap: 24px;

  .image-group {
    border: 1px solid #ababab;
    border-radius: 12px;
    width: 24%;
    overflow: hidden;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .company-infor {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    div {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.4rem;
      margin-bottom: 16px;
    }

    span {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: black;
      margin-bottom: 8px;
    }
  }
}
