.reg-competition-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .detail-name {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;

        input {
            width: 100%;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            padding-left: 10px;
            border-radius: 10px;
        }
    }

    .detail-phone {
        width: 100%;

        input {
            width: 100%;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            padding-left: 10px;
            border-radius: 10px;
        }
    }

    .register-btn {
        width: 100%;
        display: flex;
        justify-content: right;
        gap: 10px;
    }

    .ant-row.ant-form-item-row {
        display: block;
    }
}
